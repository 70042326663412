<template>
  <div>
    <div v-if="targetingType == 'map_inputs' && mapType == 'mapbox'">
      <v-col><h5>Selected Regions:</h5></v-col>
      <!-- <v-col v-if="map_regions.length">
        <v-row class="ma-1">
          <div v-for="region in map_regions" :key="region.name">
            <v-menu
              bottom
              right
              transition="scale-transition"
              origin="top left"
            >
              <template v-slot:activator="{ on }">
                <v-chip
                  pill
                  v-on="on"
                  close
                  @click:close="deleteLocation(region.name)"
                  draggable
                >
                  {{ region.name }}
                </v-chip>
              </template>
              <v-card width="400">
                <v-list dark>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title
                        >Range around {{ region.name }}</v-list-item-title
                      >
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-btn icon @click="menu = false">
                        <v-icon>mdi-close-circle</v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
                <v-col>
                  <v-row class="ma-2">
                    <v-slider
                      prepend-icon="mdi-map-marker-distance"
                      v-bind:value="region.range / 1000"
                      @change="updateRange({ range: $event, region: region })"
                      :label="(region.range / 1000).toString()"
                      thumb-label="always"
                      min="1"
                    ></v-slider>
                    Km
                  </v-row>
                </v-col>
              </v-card>
            </v-menu>
          </div>
        </v-row>
      </v-col> -->
      <v-col>
        <h5 class="font-weight-regular">Search a location to add...</h5>
        <h5 class="font-weight-bold">Tap on added locations to adjust range</h5>
      </v-col>
      <div id="basemap" ref="basemap" v-intersect="resizeMap"></div>
    </div>

    <div v-else>
      <div>
        <div class="py-3">
          <v-autocomplete
            v-model="place"
            :items="places"
            outlined
            return-object
            dense
            item-text="description"
            chips
            small-chips
            label="Search for a location"
            :search-input.sync="searchPlaces"
            :loading="isLoading"
            hide-details
          ></v-autocomplete>
        </div>

        <div class="py-2">
          <div v-for="(location, index) in locations" :key="index">
            <v-menu
              bottom
              right
              transition="scale-transition"
              origin="top left"
            >
              <template v-slot:activator="{ on }">
                <v-chip
                  pill
                  v-on="on"
                  close
                  @click:close="deleteGoogleLocation(location.name)"
                  draggable
                >
                  {{ location.name }}
                </v-chip>
              </template>
              <v-card width="400">
                <v-list dark>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title
                        >Range around {{ location.name }}</v-list-item-title
                      >
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-btn icon @click="menu = false">
                        <v-icon>mdi-close-circle</v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
                <v-col>
                  <v-row class="ma-2">
                    <v-slider
                      prepend-icon="mdi-map-marker-distance"
                      v-bind:value="location.radius / 1000"
                      v-on:input="
                        updateGoogleRange({
                          range: $event,
                          location: location,
                        })
                      "
                      :label="(location.radius / 1000).toString()"
                      thumb-label="always"
                      min="1"
                    ></v-slider>
                    Km
                  </v-row>
                </v-col>
              </v-card>
            </v-menu>
          </div>
        </div>

        <Gmap-Map
          style="width: 100%; height: 500px"
          :zoom="zoom"
          :center="center"
        >
          <GmapCircle
            v-for="(location, index) in locations"
            :key="index"
            :center="{ lat: location.lat, lng: location.lng }"
            :radius="location.radius"
            :options="circleOptions"
          />

          <GmapPolygon
            v-for="(poly, index) in polygons"
            :key="index"
            :paths="poly.paths"
            :options="polygonOptions"
          />
        </Gmap-Map>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios"
import mapboxgl from "mapbox-gl"
import "mapbox-gl/dist/mapbox-gl.css"
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder"
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css"
import { Loader } from "@googlemaps/js-api-loader"

export default {
  data: () => ({
    isLoading: false,
    mapType: "google",
    accessToken: process.env.VUE_APP_MAPBOX_API_KEY,
    mapStyle: "mapbox://styles/mapbox/streets-v12",
    coordinates: [36.8219, -1.2921],
    responseData: [],
    map: {},
    zoom: 12,
    center: { lat: -1.2921, lng: 36.8219 },
    geocoder: {},
    map_regions: [],
    regions: [],
    countries: [],
    network_data: [],
    bounds: null,
    place: null,
    places: [],
    searchPlaces: null,
    locations: [],
    circleOptions: {
      strokeColor: "#FF0000",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#FF0000",
      fillOpacity: 0.35,
    },
    polygons: [],
    polygonOptions: {
      strokeColor: "#ffb01a",
      strokeOpacity: 1,
      strokeWeight: 2,
      fillColor: "#ffb01a",
      fillOpacity: 0.15,
    },
  }),
  mounted() {
    if (this.mapType == "mapbox") {
      mapboxgl.accessToken = this.accessToken
      this.generateMap()
    }
  },
  computed: {
    __center() {
      return { lat: -1.2921, lng: 36.8219 }
    },
    __markers() {
      return this.locations.map((location) => {
        return {
          position: {
            lat: location.lat,
            lng: location.lng,
          },
        }
      })
    },
  },
  props: {
    targetingType: {},
    region: {},
    stored_coordinates: {},
    selected_countries: [],
    target: null,
  },
  watch: {
    searchPlaces(val) {
      const loader = new Loader({
        apiKey: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
        version: "weekly",
        libraries: ["places"],
      })

      //create an array of countries iso_2
      let countries = this.selected_countries.map((country) => {
        return country["ISO3166-1-Alpha-2"]
      })

      //convert counstries to lowercase
      countries = countries.map((country) => {
        return country.toLowerCase()
      })

      loader.load().then(async () => {
        const google = await loader.load()

        const autocompleteService =
          await new google.maps.places.AutocompleteService()

        autocompleteService.getPlacePredictions(
          {
            input: val,
            types: ["geocode"], // You can adjust the types as needed (e.g., "address", "establishment", etc.).
            componentRestrictions: { country: countries },
          },
          (predictions, status) => {
            if (status === "OK") {
              this.places = predictions
            } else {
              this.places = []
            }
          }
        )
      })
    },
    place() {
      if (this.place) {
        const loader = new Loader({
          apiKey: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
          version: "weekly",
          libraries: ["places"],
        })

        loader.load().then(async () => {
          const google = await loader.load()

          const geocoder = await new google.maps.Geocoder()

          geocoder.geocode(
            {
              placeId: this.place.place_id,
            },
            (results, status) => {
              if (status === "OK" && results.length > 0) {
                const location = results[0].geometry.location

                let lat = location.lat()
                let lng = location.lng()

                let data = [
                  {
                    lat: lat,
                    lng: lng,
                    name: this.removeCommas(this.place.description),
                    radius: 4000,
                  },
                ]

                //push data to markers
                this.locations.push(...data)

                //emit data to parent
                let formattedData = this.locations.map((location) => {
                  return {
                    name: location.name,
                    lat: location.lat,
                    long: location.lng,
                    range: location.radius,
                  }
                })

                

                this.$emit("setGeoRegions", formattedData)

                this.zoom = 11
                //center maps to the last location
                this.center = { lat: lat, lng: lng }

                this.place = null
              } else {
                this.place = null
              }
            }
          )
        })
      }
    },

    selected_countries() {
      // Loop through the selected countries
      this.selected_countries.forEach((country) => {
        // Use bracket notation to access the property
        const isoCode = country["ISO3166-1-Alpha-2"]

        if (isoCode) {
          // Fetch country polygon using the ISO3166-1-Alpha-2 code
          this.fetchCountryPolygon(isoCode)
        } else {
          console.error(
            "ISO3166-1-Alpha-2 code not found for country:",
            country
          )
        }
      })
    },

    async region() {
      //Combine region and stored coordinates to create locations data
      this.locations = this.region.map((location, index) => {
        return {
          lat: this.stored_coordinates[index * 3],
          lng: this.stored_coordinates[index * 3 + 1],
          name: location,
          radius: this.stored_coordinates[index * 3 + 2],
        }
      })

      //Center map to the last location
      if (this.locations.length) {
        this.center = {
          lat: this.locations[this.locations.length - 1].lat,
          lng: this.locations[this.locations.length - 1].lng,
        }

        //set zoom
        this.zoom = 13
      }

      // this.responseData = []
      // this.regions = []
      // this.countries = []

      // if (this.region.length) {
      //   for (let index = 0; index < this.region.length; index++) {
      //     await this.fetchCoordinates(this.region[index])
      //   }
      //   for (let i = 0; i < this.map_regions.length; i++) {
      //     const rangeIndex = i * 3 + 2
      //     if (rangeIndex < this.stored_coordinates.length) {
      //       this.map_regions[i].range = this.stored_coordinates[rangeIndex]
      //     }
      //   }
      //   this.$emit("setCountries", this.map_regions)
      //   this.$emit("setGeoRegions", this.map_regions)
      //   this.populateMap()
      // }
    },
  },
  methods: {
    removeCommas(string) {
      return string.replace(/,/g, "")
    },
    async fetchCountryPolygon(countryName) {
      try {
        // Fetch GeoJSON data from GitHub
        const response = await axios.get(
          `https://raw.githubusercontent.com/datasets/geo-countries/master/data/countries.geojson`
        )

        // Find the country feature
        const countryFeature = response.data.features.find(
          (feature) =>
            feature.properties.ISO_A2.toLowerCase() ===
            countryName.toLowerCase()
        )

        if (countryFeature) {
          // Extract coordinates and update the map
          const coordinates = countryFeature.geometry.coordinates

          // Handle MultiPolygon and Polygon
          let paths = []
          if (countryFeature.geometry.type === "MultiPolygon") {
            coordinates.forEach((polygon) => {
              polygon.forEach((ring) => {
                const ringPaths = ring.map((coord) => ({
                  lat: coord[1],
                  lng: coord[0],
                }))
                // Ensure the ring is closed
                if (
                  ringPaths.length > 0 &&
                  !(
                    ringPaths[0].lat === ringPaths[ringPaths.length - 1].lat &&
                    ringPaths[0].lng === ringPaths[ringPaths.length - 1].lng
                  )
                ) {
                  ringPaths.push(ringPaths[0])
                }
                paths.push(ringPaths)
              })
            })
          } else if (countryFeature.geometry.type === "Polygon") {
            coordinates.forEach((ring) => {
              const ringPaths = ring.map((coord) => ({
                lat: coord[1],
                lng: coord[0],
              }))
              // Ensure the ring is closed
              if (
                ringPaths.length > 0 &&
                !(
                  ringPaths[0].lat === ringPaths[ringPaths.length - 1].lat &&
                  ringPaths[0].lng === ringPaths[ringPaths.length - 1].lng
                )
              ) {
                ringPaths.push(ringPaths[0])
              }
              paths.push(ringPaths)
            })
          }

          // Add the polygon to the map
          this.polygons.push({
            paths,
          })

          //set zoom to 4 only when creating a target
          if (this.target) {
            this.zoom = 4
          }
        } else {
          throw new Error("Country not found in GeoJSON data")
        }
      } catch (error) {
        console.error("Error fetching country polygon:", error)
      }
    },
    updateGoogleRange({ range, location }) {
      const index = this.locations.findIndex(
        (obj) => obj.name === location.name
      )
      if (index !== -1) {
        this.locations[index].radius = range * 1000
      }

      //emit data to parent
      let formattedData = this.locations.map((location) => {
        return {
          name: location.name,
          lat: location.lat,
          long: location.lng,
          range: location.radius,
        }
      });

      this.$emit("setGeoRegions", formattedData)
    },
    deleteGoogleLocation(region) {
      const index = this.locations.findIndex((obj) => obj.name === region)
      if (index !== -1) {
        this.locations.splice(index, 1)
      }

      //Center map to the last location
      if (this.locations.length) {
        this.center = {
          lat: this.locations[this.locations.length - 1].lat,
          lng: this.locations[this.locations.length - 1].lng,
        }
      }
    },
    generateMap() {
      this.map = new mapboxgl.Map({
        container: this.$refs.basemap, // container ID
        style: this.mapStyle, // style URL
        center: this.coordinates, // starting position [lng, lat]
        zoom: 6, // starting zoom
      })
      this.geocoder = new MapboxGeocoder({
        accessToken: this.accessToken,
        mapboxgl: mapboxgl,
        getItemValue: this.addMapLocation,
      })
      this.map.addControl(this.geocoder)
    },
    populateMap() {
      for (let i = 0; i < this.map_regions.length; i++) {
        new mapboxgl.Marker({
          color: "#3586d6",
        })
          .setLngLat([this.map_regions[i].long, this.map_regions[i].lat])
          .addTo(this.map)
      }
    },
    addMapLocation(result) {
      // Find country name of location
      let country_name = ""
      if (result.place_type.includes("country")) {
        country_name = result.properties.short_code.toUpperCase()
      } else {
        let country_object = result.context.find((obj) => {
          if (obj.id && typeof obj.id === "string") {
            return obj.id.startsWith("country")
          }
          return ""
        })
        country_name = country_object.short_code.toUpperCase()
      }

      //Develop location co-ordinates
      const place = {
        name: result.place_name.split(",")[0],
        long: result.center[0],
        lat: result.center[1],
        range: 10000,
        country: country_name,
      }

      //Add map marker
      new mapboxgl.Marker({
        color: "#3586d6",
      })
        .setLngLat([place.long, place.lat])
        .addTo(this.map)
      this.map_regions.push(place)
      this.$emit("setCountries", this.map_regions)
      this.$emit("setGeoRegions", this.map_regions)
      return result.place_name
    },
    deleteLocation(region) {
      const index = this.map_regions.findIndex((obj) => obj.name === region)
      if (index !== -1) {
        this.map_regions.splice(index, 1)
      }
      this.generateMap()
      this.populateMap()
      this.$emit("setCountries", this.map_regions)
      this.$emit("setGeoRegions", this.map_regions)
    },
    updateRange({ range, region }) {
      const index = this.map_regions.findIndex(
        (obj) => obj.name === region.name
      )
      if (index !== -1) {
        this.map_regions[index].range = range * 1000
        this.$emit("setGeoRegions", this.map_regions)

      }
    },
    resizeMap() {
      this.map.resize()
    },
    async fetchCoordinates(region) {
      const name_fetch = this.parseRegionName(region)
      try {
        const { data } = await axios.get(
          `https://api.openweathermap.org/geo/1.0/direct?q=${name_fetch}&limit=5&appid=${process.env.VUE_APP_GEOCODING_API_KEY}`
        )
        if (this.countries.length) {
          this.responseData.push(
            data.filter((place) => this.countries.includes(place.country))[0]
          )
        } else {
          this.responseData.push(data[0])
        }
        this.responseData = Object.values(
          this.responseData.reduce(
            (acc, obj) => ({ ...acc, [obj.name]: obj }),
            {}
          )
        )
        this.refineCoordinates(this.responseData)
      } catch (error) {
        console.log(error)
      }
    },
    refineCoordinates(geo_data) {
      if (geo_data.length) {
        this.network_data = geo_data.map((object) => {
          return {
            name: object.name,
            lat: object.lat,
            long: object.lon,
            range: 10000,
            country: object.country,
          }
        })
      }
      this.map_regions = this.network_data
      this.$emit("setCountries", this.map_regions)
      this.$emit("setGeoRegions", this.map_regions)
    },
    parseRegionName(name) {
      String.prototype.remove = function (s) {
        return this.replace(s, "")
      }
      name = name.remove("County")
      name = name.remove("Region")
      name = name.remove("Province")
      return name
    },
  },
}
</script>

<style lang="scss" scoped>
#basemap {
  height: 500px;
}
</style>
