<template>
  <v-row>
    <v-col cols="8">
      <v-card flat>
        <v-form
          lazy-validation
          v-model="valid"
          ref="targeting_form"
          class="campaign-form"
        >
          <v-card-text>
            <v-row dense class="flex-column">
              <v-col>
                <h5 class="mb-">
                  Target Names <span class="error--text">*</span>
                </h5>
                <v-text-field
                  v-model="target_name"
                  outlined
                  dense
                  :rules="[(v) => !!v || 'Target name is required!']"
                  required
                  placeholder="Target Name"
                  persistent-placeholder
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <!-- not required for super admin--->
              <v-col v-if="!superAdmin">
                <h5 class="mb-1">
                  Target Client <span class="error--text">*</span>
                </h5>
                <v-select
                  :items="clients"
                  outlined
                  dense
                  clearable
                  :rules="
                    superAdmin
                      ? []
                      : [(v) => !!v || 'Target client is required!']
                  "
                  item-text="client_name"
                  item-value="id"
                  v-model="client"
                  hide-details="auto"
                  placeholder="Select Target Client"
                  persistent-placeholder
                  :menu-props="{ bottom: true, offsetY: true }"
                ></v-select>
              </v-col>
              <v-col v-else>
                <h5 class="mb-1">Target Client</h5>
                <v-select
                  :items="clients"
                  outlined
                  dense
                  clearable
                  item-text="client_name"
                  item-value="id"
                  v-model="client"
                  hide-details="auto"
                  placeholder="Select Target Client"
                  persistent-placeholder
                  :menu-props="{ bottom: true, offsetY: true }"
                ></v-select>
              </v-col>
              <v-divider class="my-5"></v-divider>
              <v-col v-if="!this.appCampaign">
                <h5>Domain List Type</h5>
                <v-radio-group
                  v-model="domain_targetting"
                  row
                  dense
                  class="mt-0"
                >
                  <v-radio value="whitelist" label="Whitelist"> </v-radio>
                  <v-radio value="blacklist" label="Blacklist"> </v-radio>
                </v-radio-group>

                <h5 class="mb-2">Domains</h5>

                <v-textarea
                  v-model="domains"
                  auto-grow
                  filled
                  label="Enter domains here, comma separated"
                  rows="5"
                ></v-textarea>
              </v-col>

              <v-col v-if="false">
                <h5 class="mb-1">Geo Targeting</h5>
                <v-btn-toggle
                  v-model="geo_targeting_type"
                  color="primary"
                  variant="outlined"
                >
                  <v-btn :disabled="geo_targeting_state.map">
                    <v-icon left> mdi-book-marker </v-icon>
                    Inputs
                  </v-btn>

                  <v-btn :disabled="geo_targeting_state.tree">
                    <v-icon left> mdi-map-marker-multiple-outline </v-icon>
                    Map
                  </v-btn>
                </v-btn-toggle>
              </v-col>

              <v-col>
                <h5 class="mb-1" :disabled="geo_targeting_state.map">
                  Country/s<span class="error--text">*</span>
                </h5>
                <v-combobox
                  outlined
                  dense
                  label="Select country"
                  v-model="country"
                  :items="countries"
                  :item-text="(country) => country.official_name_en"
                  small-chips
                  multiple
                  hide-details="auto"
                  clearable
                  deletable-chips
                  :menu-props="{ bottom: true, offsetY: true }"
                  :disabled="geo_targeting_state.map"
                  :rules="[(v) => !!v.length || 'Country is required!']"
                ></v-combobox>
              </v-col>

              <div v-if="__geoTargetingType == 'geo_inputs' || false">
                <h5 class="mb-1">Locations</h5>
                <treeselect
                  v-model="selected_locations"
                  :multiple="true"
                  :emit-value="true"
                  value-format="string"
                  :options="locations"
                  placeholder="Choose Location(s)"
                  :disabled="geo_targeting_state.map"
                />
              </div>

              <google-geo-targeting
                v-else
                @setGeoRegions="parseMapGeoRegions($event)"
                @setCountries="setCountries($event)"
                :targetingType="__geoTargetingType"
                :region="stored_geo_regions"
                :stored_coordinates="stored_coordinates"
                :selected_countries="country"
              />

              <v-divider class="my-5"></v-divider>

              <v-col>
                <h5 v-if="country.length > 0" class="mb-1">Carriers</h5>
                <v-combobox
                  outlined
                  v-if="country.length > 0"
                  dense
                  label="e.g. Safaricom"
                  :items="filtered_carriers"
                  v-model="carrier"
                  small-chips
                  multiple
                  hide-details="auto"
                  item-text="carrier_name"
                  :disabled="!filtered_carriers.length"
                  clearable
                  deletable-chips
                  :menu-props="{ bottom: true, offsetY: true }"
                ></v-combobox>
              </v-col>
              <v-col v-if="this.appCampaign">
                <h5 class="mb-1">Operating System</h5>
                <v-combobox
                  hide-details="auto"
                  outlined
                  dense
                  label="e.g. Android, Windows 10"
                  :items="mobile_os"
                  small-chips
                  multiple
                  v-model="os"
                  item-text="platform_name"
                  clearable
                  deletable-chips
                  :menu-props="{ bottom: true, offsetY: true }"
                ></v-combobox>
              </v-col>
              <v-col v-if="!this.appCampaign">
                <h5 class="mb-1">Operating System</h5>
                <v-combobox
                  hide-details="auto"
                  outlined
                  dense
                  label="e.g. Android, Windows 10. Leave empty to select all"
                  :items="mobile_os"
                  small-chips
                  multiple
                  v-model="os"
                  clearable
                  deletable-chips
                  item-text="platform_name"
                  :menu-props="{ bottom: true, offsetY: true }"
                ></v-combobox>
              </v-col>
              <v-col>
                <h5 class="mb-1">
                  Phone Make<span class="error--text">*</span>
                </h5>
                <v-combobox
                  hide-details="auto"
                  outlined
                  dense
                  label="e.g. Samsung, Apple"
                  :items="mobile_devices"
                  small-chips
                  multiple
                  v-model="make"
                  clearable
                  deletable-chips
                  item-text="make"
                  :menu-props="{ bottom: true, offsetY: true }"
                ></v-combobox>
              </v-col>
              <v-col>
                <h5 v-if="make.length" class="mb-1">Phone Model</h5>
                <v-combobox
                  v-if="make.length"
                  hide-details="auto"
                  outlined
                  dense
                  label="Select a phone make above to view models"
                  :items="models"
                  small-chips
                  multiple
                  v-model="model"
                  :disabled="!models.length"
                  clearable
                  deletable-chips
                  item-text="model"
                  :menu-props="{ bottom: true, offsetY: true }"
                ></v-combobox>
              </v-col>
              <v-col>
                <h5 class="mb-1">Device Types</h5>
                <v-combobox
                  hide-details="auto"
                  outlined
                  dense
                  v-model="device_type"
                  label="Choose a Device type"
                  :items="device_types"
                  small-chips
                  multiple
                  clearable
                  deletable-chips
                  :menu-props="{ bottom: true, offsetY: true }"
                ></v-combobox>
              </v-col>
              <v-col>
                <h5 class="mb-1">Verticals WhiteList</h5>
                <treeselect
                  v-model="whitelisted_verticals"
                  :multiple="true"
                  :emit-value="true"
                  value-format="string"
                  :options="options"
                  placeholder="Choose Vertical(s)"
                />
              </v-col>
              <v-col>
                <h5 class="mb-1">Verticals BlackList</h5>
                <v-combobox
                  hide-details="auto"
                  outlined
                  dense
                  v-model="blacklisted_verticals"
                  label="Choose Vertical(s)"
                  :items="contentLabels"
                  item-text="displayName"
                  small-chips
                  multiple
                  clearable
                  deletable-chips
                  :menu-props="{ bottom: true, offsetY: true }"
                ></v-combobox>
              </v-col>
              <!-- <h5 class="mb-2">Customer ID</h5>
          <v-text-field
            v-model="customer_id"
            outlined
            dense
            class="mb-4"
            disabled
          ></v-text-field> -->
            </v-row>
          </v-card-text>
          <v-card-actions class="d-flex justify-end">
            <v-btn
              :loading="loading"
              :disabled="loading"
              depressed
              color="primary"
              @click="submit"
              v-if="
                $route.name == 'add-programmatic-audience' ||
                $route.name == 'new-campaign' ||
                this.$route.name === 'add-opera-audience' ||
                this.$route.name === 'add-truecaller-audience' ||
                this.$route.name === 'add-transsion-audience' ||
                this.$route.name === 'add-phoenix-audience' ||
                this.$route.name === 'add-ctv-audience' ||
                this.$route.name === 'add-gaming-audience'
              "
              >Create Target</v-btn
            >
            <v-btn
              :loading="loading"
              :disabled="loading"
              depressed
              color="primary"
              @click.stop="updateTarget"
              v-else
            >
              Update Target
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-col>

    <v-col cols="4">
      <v-card flat>
        <template slot="progress">
          <v-progress-linear
            color="deep-purple"
            height="10"
            indeterminate
          ></v-progress-linear>
        </template>

        <v-card-title>{{ target_name }}</v-card-title>

        <v-card-text>
          <div class="text-subtitle-1">
            Domain List Type • {{ domain_targetting }}
          </div>

          <div class="text-subtitle-1">
            Country(s) •
            {{
              country?.map((country) => country?.official_name_en).join(", ")
            }}
          </div>
        </v-card-text>

        <v-divider class="mx-4"></v-divider>

        <v-card-title class="py-1">Domains</v-card-title>

        <v-card-text class="py-0">
          <v-chip-group active-class="deep-purple accent-4 white--text" column>
            <v-chip v-for="(domain, index) in __domains" :key="index" small>{{
              domain
            }}</v-chip>
          </v-chip-group>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import Treeselect from "@riophae/vue-treeselect"
import "@riophae/vue-treeselect/dist/vue-treeselect.css"
import axios from "axios"
import GoogleGeoTargeting from "./GoogleGeoTargeting.vue"
export default {
  components: {
    Treeselect,
    GoogleGeoTargeting,
  },
  data: () => ({
    appCampaign: false,
    client: "",
    client_id: "",
    search: null,
    domain_targetting: "whitelist",
    geo_targeting_state: {
      map: false,
      tree: false,
    },
    responseData: [],
    ip_list_type: null,
    domains: "",
    geo_targeting_type: 1,
    all_domains: [],
    customer_id: 1,
    target_name: "",
    country: [],
    os: [],
    region: [],
    selected_locations: [],
    coordinates: [],
    stored_coordinates: [],
    stored_geo_regions: [],
    model: [],
    latitude: null,
    longitude: null,
    geo_range: null,
    device_type: [],
    iabwhitelist: [],
    iabblacklist: [],
    whitelisted_ip_addresses: [],
    device_types: [
      "Mobile/Tablet",
      "Personal Computer",
      "Connected TV",
      "Phone",
      "Tablet",
      "Connected Device",
      "Set Top Box",
    ],
    phones_make: [],
    make: [],
    filtered_carriers: [],
    carrier: [],
    models: [],
    valid: true,
    iab_category: [],
    iab_category_blklist: [],
    activate_time: null,
    expire_time: null,
    iab_categories: [],
    whitelisted_verticals: [],
    blacklisted_verticals: [],
    value: [],
    whitelisted_categories: [],
    blacklisted_categories: [],
    os_platforms: [],
    selected_carriers: [],
    selected_models: [],
    contentLabels: [
      {
        id: "6",
        displayName: "Parked domains",
      },
      {
        id: "40",
        displayName: "Digital Content Label for DL-PG rating",
      },
      {
        id: "20",
        displayName: "Downloads and file sharing",
      },
      {
        id: "42",
        displayName: "Digital Content Label for DL-MA rating",
      },
      {
        id: "119",
        displayName: "Gambling",
      },
      {
        id: "41",
        displayName: "Digital Content Label for DL-T rating",
      },
      {
        id: "44",
        displayName: "Live Streaming Video",
      },
      {
        id: "66",
        displayName: "Drugs",
      },
      {
        id: "43",
        displayName: "Digital Content Not Yet Rated",
      },
      {
        id: "65",
        displayName: "Violence",
      },
      {
        id: "26",
        displayName: "Below the fold or unknown position",
      },
      {
        id: "121",
        displayName: "Tobacco",
      },
      {
        id: "120",
        displayName: "Politics",
      },
      {
        id: "160",
        displayName: "Content could not be crawled (label status unknown)",
      },
      {
        id: "126",
        displayName: "Alcohol",
      },
      {
        id: "125",
        displayName: "Religious",
      },
      {
        id: "124",
        displayName: "Transportation accidents",
      },
      {
        id: "146",
        displayName: "Sensitive social issues",
      },
      {
        id: "123",
        displayName: "Derogatory",
      },
      {
        id: "122",
        displayName: "Weapons",
      },
      {
        id: "52",
        displayName: "Profanity",
      },
      {
        id: "13",
        displayName: "Suggestive",
      },
      {
        id: "56",
        displayName: "Tragedy",
      },
      {
        id: "39",
        displayName: "Digital Content Label for DL-G rating",
      },
      {
        id: "16",
        displayName: "Shocking",
      },
      {
        id: "38",
        displayName: "Sexual",
      },
    ],
  }),
  created() {
    this.getStaticDspData()
  },
  async mounted() {
    if (
      this.$route.name === "add-opera-audience" ||
      this.$route.name === "add-truecaller-audience" ||
      this.$route.name === "add-transsion-audience" ||
      this.$route.name === "add-phoenix-audience" ||
      this.$route.name === "add-ctv-audience" ||
      this.$route.name === "add-gaming-audience"
    ) {
      this.appCampaign = true
    }
  },
  props: {
    single_target: {
      type: Object,
    },
    carriers: {
      type: Array,
    },
    mobile_os: {
      type: Array,
    },
    phone_dataset: {
      type: Array,
    },
    mobile_devices: {
      type: Array,
    },
    loading: {
      type: Boolean,
    },
  },

  methods: {
    async getStaticDspData() {
      try {
        await this.$store.dispatch("dsp/getStaticDspData")
      } catch (error) {
        this.error = error
      }
    },
    parseCoordinates(params) {
      this.coordinates = params
        .map((item) => [item.lat, item.long, item.range])
        .flat()
    },
    parseMapGeoRegions(map_regions) {
      this.coordinates = map_regions
        .map((item) => [item.lat, item.long, item.range])
        .flat()
      this.region = map_regions.map((item) => item.name)
    },
    setCountries(data) {
      const formated_countries = data.map((item) => item.country)
      this.country = this.countries.filter((country) =>
        formated_countries.includes(country["ISO3166-1-Alpha-2"])
      )
    },
    setLocations(data) {
      const formated_countries = data.map((item) => item.country)
      this.country = this.countries.filter((country) =>
        formated_countries.includes(country["ISO3166-1-Alpha-2"])
      )
      this.region = data.map((item) => item.name)
    },
    async validate() {
      await this.$refs.targeting_form.validate()
    },
    sanitizeAndFormatRegionsAndCoordinates(array) {
      //reomve empty strings
      let filtered = array.filter((item) => item !== "")
      return filtered
    },
    async submit() {
      await this.validate()

      if (!this.valid) return

      let body = {
        name: this.target_name,
        client_id: this.client,
        customer_id: this.customer_id,
        country: this.country.map((country) => country["ISO3166-1-Alpha-3"]),
        device_type: this.device_type,
        carrier: this.selected_carriers,
        list_of_domains: this.__domains,
        domain_targetting: this.domain_targetting,
        geo_region: this.region,
        geo_latitude: this.latitude,
        geo_longitude: this.longitude,
        geo_range: this.geo_range,
        os: this.os_platforms,
        make: this.phones_make,
        model: this.selected_models,
        iab_category: this.whitelisted_categories,
        iab_category_blklist: this.blacklisted_categories,
        activate_time: this.activate_time,
        expire_time: this.expire_time,
        //Latitudes + Longitudes Error Management
        geo: this.coordinates,
        geo_type: this.__geoTargetingType,
      }

      this.$emit("create", body)
    },
    async updateTarget() {
      await this.$refs.targeting_form.validate()
      if (!this.valid) return

      let body = {
        name: this.target_name,
        client_id: this.client,
        customer_id: this.customer_id,
        country: this.country.map((country) => country["ISO3166-1-Alpha-3"]),
        device_type: this.device_type,
        carrier: this.selected_carriers,
        list_of_domains: this.__domains,
        domain_targetting: this.domain_targetting,
        geo_latitude: this.latitude,
        geo_longitude: this.longitude,
        geo_range: this.geo_range,
        geo_region: this.region,
        os: this.os_platforms,
        make: this.phones_make,
        model: this.selected_models,
        iab_category: this.whitelisted_categories,
        iab_category_blklist: this.blacklisted_categories,
        activate_time: this.activate_time,
        expire_time: this.expire_time,
        //Latitudes + Longitudes Error Management
        geo: this.coordinates,
      }

      this.$emit("update", body)
    },
    //Get Clients
    async getClients() {
      try {
        this.$store.commit("makingApiRequest", true)
        await this.$store.dispatch("readClients")
        this.$store.commit("makingApiRequest", false)
      } catch (error) {
        this.error = error
        this.$store.commit("makingApiRequest", false)
      }
    },
    findById(array, value) {
      for (let i = 0; i < array.length; i++) {
        const obj = array[i]
        if (obj.id === value) {
          return obj
        }
        if (obj.children) {
          const childMatch = this.findById(obj.children, value)
          if (childMatch) {
            return childMatch
          }
        }
      }
      // return [];
    },
    findByName(array, value) {
      for (let i = 0; i < array.length; i++) {
        const obj = array[i]
        if (obj.label === value) {
          return obj
        }
        if (obj.children) {
          const childMatch = this.findByName(obj.children, value)
          if (childMatch) {
            return childMatch
          }
        }
      }
    },
    async fetchCoordinates(region) {
      const name_fetch = this.parseRegionName(region)
      try {
        const { data } = await axios.get(
          `https://api.openweathermap.org/geo/1.0/direct?q=${name_fetch}&limit=5&appid=${process.env.VUE_APP_GEOCODING_API_KEY}`
        )
        const coordinates = data.filter((location) => {
          return this.country.some(
            (isoCode) => isoCode["ISO3166-1-Alpha-2"] === location.country
          )
        })
        this.responseData.push(coordinates)
        this.refineCoordinates(this.responseData)
      } catch (error) {
        console.log(error)
      }
    },
    refineCoordinates(geo_data) {
      let data = []
      for (let index = 0; index < geo_data.length; index++) {
        const element = geo_data[index][0]
        data.push({
          name: element.name,
          lat: element.lat,
          long: element.lon,
          range: 10000,
          country: element.country,
        })
      }
      this.parseCoordinates(data)
    },
    parseRegionName(name) {
      String.prototype.remove = function (s) {
        return this.replace(s, "")
      }
      name = name.remove("County")
      name = name.remove("Region")
      name = name.remove("Province")
      return name
    },
  },
  watch: {
    single_target() {

      let domainData =
        this.single_target.list_of_domains != null
          ? this.sanitizeAndFormatRegionsAndCoordinates(
              this.single_target.list_of_domains
            )
          : []

      this.domains = domainData.join(", ")

      this.region =
        this.single_target.geo_region != null
          ? this.sanitizeAndFormatRegionsAndCoordinates(
              this.single_target.geo_region
            )
          : []

      this.coordinates =
        this.single_target.geo != null
          ? this.sanitizeAndFormatRegionsAndCoordinates(this.single_target.geo)
          : []

      this.stored_geo_regions = this.single_target.geo_region
      this.stored_coordinates = this.single_target.geo
      this.geo_targeting_type = 1

      this.target_name = this.single_target?.name;

      this.customer_id = 1;

      this.domain_targetting = this.single_target?.domain_targetting

      this.whitelisted_ip_addresses = this.single_target?.whitelisted_ip_addresses

      this.geo_latitude = this.single_target?.geo_latitude;
      this.geo_longitude = this.single_target?.geo_longitude

      this.country = this.single_target?.country
        ? this.countries?.filter((country) =>
            this.single_target?.country?.includes(country["ISO3166-1-Alpha-3"])
          )
        : null

      if (this.single_target?.carrier?.length > 0) {

        let carrier_countries = this.country?.map(
          (country) => country["CLDR display name"]
        )

        const carriers = this.carriers?.filter((carrier) => carrier_countries?.includes(carrier.country))
          .map((carrier) => carrier.carriers)
          .flat()

        let selected_items = []

        for (
          let index = 0;
          index < this.single_target?.carrier?.length;
          index++
        ) {
          let item = carriers.find(
            (o) => o.criterion_id == this.single_target?.carrier[index]
          )
          if (typeof item != "undefined") {
            selected_items.push(item)
          }
        }
        this.carrier = selected_items
      }

      if (this.single_target?.os?.length > 0) {
        let targets_os = []
        for (let i = 0; i < this.single_target.os.length; i++) {
          let single_targets_os = this.mobile_os.filter(
            (os) => os.platform_name === this.single_target.os[i]
          )
          if (single_targets_os.length) {
            targets_os.push(single_targets_os[0])
          }
        }
        this.os = targets_os
      }

      if (this.single_target?.make?.length > 0) {
        let target_make = []
        let make_array = this.single_target?.make.split(",")
        for (let i = 0; i < make_array.length; i++) {
          let single_targets_make = this.mobile_devices.filter(
            (device) => device.make === make_array[i]
          )
          if (single_targets_make.length) {
            target_make.push(single_targets_make[0])
          }
        }
        this.make = target_make
      }

      if (this.single_target?.model?.length > 0) {
        let target_model = []
        let model_array = this.single_target.model.split(",")
        let models = this.mobile_devices.map((item) => item.models).flat()
        for (let i = 0; i < model_array.length; i++) {
          let single_targets_model = models.filter(
            (item) => item.model === model_array[i]
          )
          if (single_targets_model.length) {
            target_model.push(single_targets_model[0])
          }
        }
        this.model = target_model
      }

      this.device_type = this.single_target?.devicetype;

      this.client = this.single_target?.client && this.single_target.client.length > 0
        ? this.single_target.client[0].id
        : ""

      if (this.single_target?.iab_category?.length > 0) {

        let whitelisted_verticals = []
        for (let i = 0; i < this.single_target.iab_category.length; i++) {
          if (this.single_target.iab_category[i].length != 0) {
            let category = this.findById(
              this.options,
              this.single_target.iab_category[i]
            )
            whitelisted_verticals.push(category)
          }
        }
        this.whitelisted_verticals = whitelisted_verticals
      }

      if (this.single_target?.iab_category_blklist?.length) {
        let blacklisted_verticals = []
        for (
          let i = 0;
          i < this.single_target.iab_category_blklist.length;
          i++
        ) {
          let category = this.contentLabels.filter(
            (label) => label.id === this.single_target.iab_category_blklist[i]
          )
          if (category.length) {
            blacklisted_verticals.push(category[0])
          }
        }
        this.blacklisted_verticals = blacklisted_verticals
      }

      //** This is the default */
      this.geo_targeting_state.map = true
      this.geo_targeting_state.tree = false
      // this.stored_geo_regions = geo_regions
      // this.stored_coordinates = geo_coordinates

      // if (this.single_target.geo_type == "map_inputs") {
      //   //** remove empty strings from the list */

      //   let geo_regions = this.single_target.geo_region != null ?  this.sanitizeAndFormatRegionsAndCoordinates(
      //     this.single_target.geo_region
      //   ) : []

      //   let geo_coordinates = this.single_target.geo != null ? this.sanitizeAndFormatRegionsAndCoordinates(
      //     this.single_target.geo
      //   ) : []

      //   //** This is the default */
      //   this.geo_targeting_state.map = true
      //   this.geo_targeting_state.tree = false
      //   this.stored_geo_regions = geo_regions
      //   this.stored_coordinates = geo_coordinates
      // } else if (this.single_target.geo_type == "geo_inputs") {
      //   this.geo_targeting_state.map = false
      //   this.geo_targeting_state.tree = true

      //   if (this.single_target.geo_region.length > 0) {
      //     let locations = []

      //     for (let i = 0; i < this.single_target.geo_region.length; i++) {
      //       if (this.single_target.geo_region[i].length != 0) {
      //         let location = this.findByName(
      //           this.locations,
      //           this.single_target.geo_region[i]
      //         )
      //         if (typeof location != "undefined") {
      //           locations.push(location)
      //         }
      //       }
      //     }

      //     this.selected_locations = locations
      //   }
      // }
    },
    selected_locations() {
      this.responseData = []
      this.region = this.selected_locations.map((item) => item.label)
    },
    country() {
      this.filtered_carriers = []
      // Clear filtered carriers upon clearing the country input
      if (!this.country.length) this.filtered_carriers = []

      let capitalized = this.country.map((country) => country.official_name_en)

      // Extract selected countries from carries static data
      const selected_countries = this.carriers.filter((obj) =>
        capitalized.includes(obj.country)
      )

      // Extract carriers from selected countries
      selected_countries
        .map((carrier) => carrier.carriers)
        .map((arr) => arr.map((ar) => this.filtered_carriers.push(ar)))
    },
    make() {
      if (this.make.length === 1) {
        this.models = this.make.map((item) => item.models)[0]
        this.phones_make = this.make.map((item) => item.make)
      }
      if (this.make.length > 1) {
        let models = this.make.map((item) => item.models)
        this.models = models.flat()
        let phones_make = this.make.map((item) => item.make)
        this.phones_make = phones_make.flat()
      }
    },
    model() {
      this.selected_models = this.model.map((item) => item.model)
    },
    whitelisted_verticals() {
      if (this.whitelisted_verticals.length) {
        this.whitelisted_verticals.map((item) => {
          if (!this.whitelisted_categories.includes(item.id)) {
            this.whitelisted_categories.push(item.id)
          }
          if ("children" in item) {
            let children = item.children
            children.map((child) => {
              if (!this.whitelisted_categories.includes(child.id)) {
                this.whitelisted_categories.push(child.id)
              }
            })
          }
        })
      } else {
        this.whitelisted_categories = []
      }
    },
    blacklisted_verticals() {
      if (this.blacklisted_verticals.length) {
        this.blacklisted_verticals.map((item) => {
          if (!this.blacklisted_categories.includes(item.id)) {
            this.blacklisted_categories.push(item.id)
          }
        })
      } else {
        this.blacklisted_categories = []
      }
    },
    os() {
      this.os_platforms = this.os.map((item) => item.platform_name)
    },
    carrier() {
      if (this.carrier.length) {
        this.selected_carriers = this.carrier.map((item) => item.criterion_id)
      }
    },
  },
  computed: {
    __domains() {
      const domains = this.domains.split(/,\s*|,\s*/)

      return domains
    },
    __geoTargetingType() {
      if (this.geo_targeting_type === 0) {
        return "geo_inputs"
      }
      if (this.geo_targeting_type === 1) {
        return "map_inputs"
      }
      return "geo_inputs"
    },
    options() {
      return this.$store.state.dsp.static_dsp_data.iab_categories.iab_categories
    },
    verticals() {
      return this.$store.state.dsp.static_dsp_data.iab_categories.iab_categories
    },
    locations() {
      let __locations = this.$store.state.dsp.static_dsp_data.locations
      if (this.country.length) {
        const set_countries = this.country.map(
          (item) => item["CLDR display name"]
        )
        __locations = __locations.filter((location) =>
          set_countries.includes(location.label)
        )
      }
      return __locations
    },
    // Extract countries from carries static data
    countries() {
      return this.$store.state.dsp.static_dsp_data.countries_v2.length
        ? this.$store.state.dsp.static_dsp_data.countries_v2
        : []
    },
    // eslint-disable-next-line vue/return-in-computed-property
    superAdmin() {
      return this.$store.getters.superAdmin
    },
    clients() {
      if (!this.$store.state.clients.length) {
        this.getClients()
      }
      return this.$store.state.clients
    },
  },
}
</script>
<style>
/* .v-select__selections {
  overflow: scroll;
  flex-wrap: nowrap;
}
.v-chip {
  overflow: initial;
} */
</style>
